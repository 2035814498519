import request from '@/utils/request'

// 查询客户的卡
export function findSimCards(data) {
  return request({
    url: `/customer/oc_sim_cards`,
    method: 'post',
    data
  })
}

// 查询 sim_card 卡基本信息
export function findSimCardBaseInfo(id) {
  return request({
    url: `/oc_sim_cards/${id}/base_info`,
    method: 'get'
  })
}

// 导入卡
export function ImportSimCards(data) {
  return request({
    url: `/customer/oc_import_sim_cards`,
    method: 'post',
    data
  })
}

