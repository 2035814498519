import request from '@/utils/request'

// 更新卡信息
export function updateSimCardsInfo(data) {
  return request({
    url: `/batch_operations/sim_cards/update_info`,
    method: 'post',
    data
  })
}

// 客户卡批量操作选项数据
export function findSimCardBatchOperationOptions() {
  return request({
    url: `/batch_operations/sim_cards/options`,
    method: 'get'
  })
}

// 三方卡批量操作选项数据
export function findOcSimCardBatchOperationOptions() {
  return request({
    url: `/batch_operations/oc_sim_cards/options`,
    method: 'get'
  })
}

// 验证划拨卡号给企业客户
export function validateAllocateSimCardsToAgency(data) {
  return request({
    url: `/batch_operations/sim_cards/allocate_cards_to_agency/validate`,
    method: 'post',
    timeout: 600 * 1000,
    data
  })
}

// 分销商划拨卡号给企业客户
export function allocateSimCardsToAgency(data) {
  return request({
    url: `/batch_operations/sim_cards/allocate_cards_to_agency`,
    method: 'post',
    data
  })
}

// 验证续期卡号
export function validateRenewSimCards(data) {
  return request({
    url: `/batch_operations/sim_cards/renew/validate`,
    method: 'post',
    timeout: 600 * 1000,
    data
  })
}

// 续期计算续期金额
export function renewCardsCalculateFee(data) {
  return request({
    url: `/batch_operations/sim_cards/renew/calculate_fee`,
    method: 'post',
    timeout: 600 * 1000,
    data
  })
}

// 续期
export function renewCards(data) {
  return request({
    url: `/batch_operations/sim_cards/renew`,
    method: 'post',
    data
  })
}

// 订购计算续期金额
export function subscribeCardsCalculateFee(data) {
  return request({
    url: `/batch_operations/sim_cards/subscribe/calculate_fee`,
    method: 'post',
    timeout: 600 * 1000,
    data
  })
}

// 卡号列表 断网
export function netDownSimCards(data) {
  return request({
    url: `/batch_operations/sim_cards/net_down`,
    method: 'post',
    data
  })
}

// 验证批量修改卡号
export function validateBatchModifySimCards(data) {
  return request({
    url: `/batch_operations/sim_cards/batch_modify/validate`,
    method: 'post',
    timeout: 600 * 1000,
    data
  })
}

// 卡号列表 恢复上网
export function netUpSimCards(data) {
  return request({
    url: `/batch_operations/sim_cards/net_up`,
    method: 'post',
    data
  })
}

// 验证设置卡激活套餐
export function validateSubscribe(data) {
  return request({
    url: `/batch_operations/sim_cards/subscribe/validate`,
    method: 'post',
    timeout: 600 * 1000,
    data
  })
}

// 分销商批量更新企业客户套餐有效性
export function subscribeProductsSearch(data) {
  return request({
    url: `batch_operations/sim_cards/subscribe/subscribe_products_search`,
    method: 'post',
    data
  })
}

// 设置卡激活套餐
export function subscribe(data) {
  return request({
    url: `/batch_operations/sim_cards/subscribe`,
    method: 'post',
    data
  })
}

// 卡号列表 设置限额
export function setSimCardsUsageLimit(data) {
  return request({
    url: `/batch_operations/sim_cards/set_usage_limit`,
    method: 'post',
    data
  })
}

// 批量修改卡号
export function batchModifySimCards(data) {
  return request({
    url: `/batch_operations/sim_cards/batch_modify`,
    method: 'post',
    data
  })
}

// 分销商查看企业客户卡 批量操作选项数据
export function findTopAgentViewAgencySimCardBatchOperationOptions() {
  return request({
    url: `/batch_operations/sim_cards/agency/by_top_agent/options`,
    method: 'get'
  })
}

// 验证短信下发
export function validateSmsDelivery(data) {
  return request({
    url: `/batch_operations/sim_cards/sms_delivery/validate`,
    method: 'post',
    timeout: 600 * 1000,
    data
  })
}

// 短信下发
export function smsDelivery(data) {
  return request({
    url: `/batch_operations/sim_cards/content_sms_delivery`,
    method: 'post',
    data
  })
}
